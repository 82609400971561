.container {
    position: relative;
    background-color: #232E3C;
    margin: 20px;
    margin-left: 290px;
    padding: 20px;
    height: calc(100dvh - 40px);
    border-radius: 20px;

    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(11.4px);
    -webkit-backdrop-filter: blur(11.4px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.profile {
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: auto;
    height: calc(100dvh - 130px);
}

.profile::-webkit-scrollbar {
    width: 10px;
}

.profile::-webkit-scrollbar-track {
    background: #8b8b8b;
    border-radius: 20px;
}

.profile::-webkit-scrollbar-thumb {
    background-color: #333;
    border-radius: 20px;
    border: 3px solid #8b8b8b;
}

.title {
    text-align: center;
    margin-bottom: 20px;
    font-weight: 500;
    color: #333;
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100dvh - 130px);
    width: 100%;
}

.profile__info {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid;
}

.profile__info__ref {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background: #fff;
    border: 1px solid;
    border-radius: 20px;
    position: relative;
    width: 300px;

    transition: background-color 0.3s ease;
}

.profile__info__ref__copy {
    background-color: #a7f3b5;
}

.reference {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 315px;
    background: #fff;
    padding: 20px;
    border-radius: 15px;
    border: 1px solid;
}

.profile__info__num {
    font-size: 40px;
    text-align: center;
}

.profile__info__text {
    font-size: 18px;
    font-weight: 400;
    text-align: center;
}

.profile__title {
    text-align: center;
    color: #333;
    font-weight: 500;
}

.hr {
    height: 1px;
    border: none;
    background: #000;
    width: 100%;
    margin: 0;
}

.profile__info__money {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}

.profile__info__money {
    display: flex;
}

.profile__info__money__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 20px;
    background: #fff;
    border: 1px solid;
    border-radius: 20px;
    width: 315px;
}

.profile__info__copy {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.profile__info__link {
    border-bottom: 1px solid;
    padding-bottom: 5px;
    margin-bottom: 5px;
    font-size: 18px;
}

.profile__info__money__list li {
    text-align: justify;
    list-style-type: none;
    text-align: justify;
    border-bottom: 1px solid;
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.button {
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 100%;
}

.menu {
    display: none;
    width: 25px;
    height: 25px;
    position: absolute;
    right: 15px;
    top: 15px;
}

.input {
    border-radius: 10px;
    padding: 10px;
    border: 1px solid;
    outline: none;
    width: 100%;
}


@media (max-width: 750px) {
    .sidebar {
        display: none;
    }

    .container {
        margin-left: 20px
    }

    .profile::-webkit-scrollbar {
        width: 0;
    }

    .profile {
        -ms-overflow-style: none;
    }

    .profile {
        overflow: -moz-scrollbars-none;
    }

    .menu {
        display: block;
    }

    .profile__info__ref {
        width: 100%;
    }

    .profile__info__text {
        font-size: 15px;
    }

    .profile__info__link {
        text-align: center;
    }
}

@media (max-width: 300px) {
    .profile__info__text {
        font-size: 13px;
    }
}