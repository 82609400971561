.section {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    padding: 20px;
}

.container {
    background: #fff;
    border: 1px solid #333;
    padding: 20px;
    border-radius: 12px;
    width: 500px;
}

.detail {
    line-height: 1.5;
    color: #333;
}

.detail span {
    font-weight: bold;
}

.title {
    text-align: center;
    color: #333;
}

.text {
    text-align: center;
}

.back {
    position: absolute;
    top: 20px;
    left: 20px;
    color: #333;
    cursor: pointer;
    width: 35px;
    height: 35px;
    border: 1px solid #333;
    border-radius: 8px;
    padding: 5px;
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100dvh - 215px);
}