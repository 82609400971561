.sidebar {
    width: 250px;
    height: calc(100dvh - 40px);
    border-radius: 20px;
    margin-left: 20px;
    margin-top: 20px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
    padding-top: 20px;

    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(11.4px);
    -webkit-backdrop-filter: blur(11.4px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.sidebar__links {
    padding-top: 20px;
}

.sidebar__links li {
    padding: 15px;
}

.sidebar__links li:hover {
    background: #333;
    cursor: pointer;
    color: #fff;
    border-radius: 12px;
}

.sidebar__link_active {
    background: #333;
    border-radius: 12px;
    color: #fff !important;
}

.sidebar__link {
    text-decoration: none;
    color: #333;
}

.sidebar h2 {
    color: #333;
    text-align: center;
}

.sidebar-links {
    list-style-type: none;
    padding: 0;
}

.sidebar-links li {
    padding: 8px;
    text-align: center;
}

.sidebar-links li a {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
}

.sidebar-links li a:hover {
    color: #f1f1f1;
}