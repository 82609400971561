.container {
    background-color: #232E3C;
    margin: 20px;
    margin-left: 290px;
    padding: 20px;
    height: calc(100dvh - 40px);
    border-radius: 20px;

    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(11.4px);
    -webkit-backdrop-filter: blur(11.4px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.container__loader {
    height: calc(100dvh - 40px);
    background: rgba(255, 255, 255, 0.2);
}

.title {
    text-align: center;
    margin-bottom: 20px;
    font-weight: 500;
    color: #333;
}

.list__item__info__wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    border-left: 1px solid;
    padding-left: 10px;
}

.list__item__sub__info {
    display: flex;
    align-items: center;
}

.list__item__icon {
    padding-right: 10px;
}

.chips {
    height: calc(100dvh - 130px);
    overflow-y: auto;
    padding-right: 10px;
    padding-left: 10px;
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100dvh - 40px);
    padding: 20px;
    margin: 20px;
    margin-left: 290px;

    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(11.4px);
    -webkit-backdrop-filter: blur(11.4px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.loader__text {
    text-align: center;
}

.list {
    list-style: none;
    overflow: auto;
    padding-right: 10px;
    padding-left: 10px;
}

.chips::-webkit-scrollbar {
    width: 10px;
}

.chips::-webkit-scrollbar-track {
    background: #8b8b8b;
    border-radius: 20px;
}

.chips::-webkit-scrollbar-thumb {
    background-color: #333;
    border-radius: 20px;
    border: 3px solid #8b8b8b;
}

.list__item__info {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    padding: 10px;
    border-radius: 12px;
    cursor: pointer;
    border: 1px solid #333;
}

.no__data {
    font-size: 16px;
    height: 57px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background: #fff;
    border: 1px solid;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.table th,
.table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.table th {
    background-color: #f4f4f4;
}

.table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.table tr:hover {
    background-color: #e0e0e0;
    cursor: pointer;
}