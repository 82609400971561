.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #232E3C;
    margin: 20px;
    margin-left: 290px;
    padding: 20px;
    height: calc(100dvh - 40px);
    border-radius: 20px;

    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(11.4px);
    -webkit-backdrop-filter: blur(11.4px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.list__wrapper {
    display: flex;
    gap: 10px;
    height: calc(100dvh - 150px);
    width: 100%;
}

.info__container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.title {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
}

.list {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.list__item__title {
    color: #333;
    margin-bottom: 5px;
}

.input__wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    gap: 5px;
}

.input {
    padding: 8px;
    border: none;
    background: transparent;
    border-bottom: 1px solid;
    font-size: 16px;
    outline: none;
    width: 300px;
}

.button {
    padding: 8px;
    width: 100%;
    margin-top: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid;
    background-color: #fff;

    transition: all 0.3s ease;
}

.button:hover {
    background-color: #333;
    color: #fff;
}

.info__wrapper {
    padding: 15px;
    border: 1px solid;
    border-radius: 10px;
    background: #fff;
}

.enter {
    transform: rotate(180deg);
    width: 30px;
    height: 30px;
    border: 1px solid #333;
    padding: 5px;
    cursor: pointer;
    border-radius: 5px;
}

.info {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.info__title {
    margin-bottom: 10px;
    color: #333;
}

.card__list__wrapper {
    flex: 1;
    padding: 15px;
    overflow: auto;
    border: 1px solid;
    border-radius: 10px;
}

.card__list__wrapper::-webkit-scrollbar {
    width: 10px;
}

.card__list__wrapper::-webkit-scrollbar-track {
    background: #8b8b8b;
    border-radius: 20px;
}

.card__list__wrapper::-webkit-scrollbar-thumb {
    background-color: #333;
    border-radius: 20px;
    border: 3px solid #8b8b8b;
}

.list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    list-style: none;
}

.list__item {
    padding: 15px;
    border: 1px solid;
    border-radius: 10px;
    background: #fff;
}

.list__item__complete {
    background: #9deeb9;
}

.list__item__process {
    background: #f7e3b5;
}

.no__history {
    font-size: 16px;
    height: 57px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid;
}

.back {
    position: absolute;
    top: 20px;
    left: 20px;
    color: #333;
    cursor: pointer;
    width: 35px;
    height: 35px;
    border: 1px solid #333;
    border-radius: 8px;
    padding: 5px;
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100dvh - 265px);
}

.info__loader {
    height: 155px;
    align-items: center;
    display: flex;
    justify-content: center;
    min-width: 300px;
}