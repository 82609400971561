.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 10;
}

.modal__container {
    padding: 20px;
}

.modal__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal__title {
    font-weight: 500;
    color: #333;
}

.menu {
    width: 25px;
    height: 25px;
    right: 15px;
    top: 15px;
}

.modal__bar {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100dvh;
    margin-top: 50px;
}

.modal__bar li:first-of-type {
    background-color: #333;
    color: #fff;
}

.modal__bar li {
    cursor: pointer;
    list-style-type: none;
    font-size: 20px;
    padding: 10px;
    text-align: center;
    border: 1px solid;
    border-radius: 10px;
}