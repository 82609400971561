/* AcceptIdentification.module.css */

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #232E3C;
    margin: 20px;
    margin-left: 290px;
    padding: 20px;
    height: calc(100dvh - 40px);
    border-radius: 20px;

    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(11.4px);
    -webkit-backdrop-filter: blur(11.4px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.title {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
}

.info {
    width: 100%;
    max-width: 600px;
    background: transparent;
    border: 1px solid #333;
    border-radius: 8px;
    padding: 20px;

    display: flex;
    align-items: start;
    justify-content: space-between;
}

.field {
    display: flex;
    gap: 5px;
    margin-bottom: 10px;
}

.text {
    font-size: 16px;
    color: #333;
}

.media {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    gap: 10px;
    border: 1px solid;
    padding: 10px;
    border-radius: 8px;
}

.media__img,
.media__video {
    width: 100%;
    height: 300px;
    border-radius: 8px;
    cursor: pointer;
}

.media__img {
    object-fit: cover;
}

.buttons {
    display: flex;
    justify-content: center;
}

.btn__wrapper {
    display: flex;
    gap: 10px;
    width: 100%;
    justify-content: center;
}

.btn {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 160px;
}

.btn__decline {
    background-color: #333;
    color: #fff;
}

.btn__decline:hover {
    background-color: #e67575;
}

.list__wrapper {
    width: 100%;
    height: calc(100dvh - 150px);
    overflow-y: auto;
    padding-right: 10px
}

.btn__accept {
    background-color: #333;
    color: #fff;
}

.btn__accept:hover {
    background-color: #4aa361;
}

.modal {
    position: absolute;
    top: 0;
    z-index: 100;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
}

.list__wrapper {
    width: 100%;
    height: calc(100dvh - 130px);
    overflow-y: auto;
    padding-right: 10px;
    padding-left: 10px;
}

.list {
    width: 100%;
}

.list__wrapper::-webkit-scrollbar {
    width: 10px;
}

.list__wrapper::-webkit-scrollbar-track {
    background: #8b8b8b;
    border-radius: 20px;
}

.list__wrapper::-webkit-scrollbar-thumb {
    background-color: #333;
    border-radius: 20px;
    border: 3px solid #8b8b8b;
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100dvh - 215px);
}

.list__item {
    padding: 10px;
    background: #fff;
    border: 1px solid;
    border-radius: 10px;
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: start;
}

.list__item__sub__info {
    display: flex;
    gap: 10px;
}

.list__item__wrapper {
    display: flex;
    gap: 10px;
}

.list__item__icon {
    padding-right: 10px;
    border-right: 1px solid;
    display: flex;
    align-items: center;
    height: 100%;
}

.list__item__info {
    display: flex;
    flex-direction: column;
}

.modalClose {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    width: 35px;
    height: 35px;
    color: #fff;
}

.info__wrapper {
    border-top: 1px solid;
    padding-top: 5px;
    margin-top: 5px;
}

.info__wrapper:first-of-type {
    border-top: none;
    padding-top: 0;
    margin-top: 0;
}

.modalContent {
    height: 100dvh;
    padding: 20px;
    border-radius: 20px;
}

.no__data {
    font-size: 16px;
    height: 57px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid;
    background: #fff;
}

.back {
    position: absolute;
    top: 20px;
    left: 20px;
    color: #333;
    cursor: pointer;
    width: 35px;
    height: 35px;
    border: 1px solid #333;
    border-radius: 8px;
    padding: 5px;
}