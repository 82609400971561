.loader {
    /* height: calc(100vh - 90px); */
    /* height: calc(100vh - 110px); */
    position: relative;
}

.loading:before,
.loading:after {
    position: absolute;
    top: 0;
    content: '';
}

.loading:before {
    left: -19.992px;
}

.loading:after {
    left: 19.992px;
    -webkit-animation-delay: 0.32s !important;
    animation-delay: 0.32s !important;
}

.loading:before,
.loading:after,
.loading {
    background: #333;
    -webkit-animation: loading-keys-app-loading 0.8s infinite ease-in-out;
    animation: loading-keys-app-loading 0.8s infinite ease-in-out;
    width: 13.6px;
    height: 32px;
}

.loading {
    text-indent: -9999em;
    margin: auto;
    position: absolute;

    right: calc(50% - 6.8px);
    top: calc(50% - 16px);
    -webkit-animation-delay: 0.16s !important;
    animation-delay: 0.16s !important;
}

@-webkit-keyframes loading-keys-app-loading {

    0%,
    80%,
    100% {
        opacity: .75;
        box-shadow: 0 0 #333;
        height: 32px;
    }

    40% {
        opacity: 1;
        box-shadow: 0 -8px #333;
        height: 40px;
    }
}

@keyframes loading-keys-app-loading {

    0%,
    80%,
    100% {
        opacity: .75;
        box-shadow: 0 0 #333;
        height: 32px;
    }

    40% {
        opacity: 1;
        box-shadow: 0 -8px #333;
        height: 40px;
    }
}