/* AcceptIdentification.module.css */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin: 20px;
    min-height: calc(100dvh - 40px);
    margin-left: 290px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(11.4px);
    -webkit-backdrop-filter: blur(11.4px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.back {
    position: absolute;
    left: 20px;
    top: 20px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    color: #000;
}

.title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
}

.media {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.media__wrapper {
    margin: 0 10px;
}

.media__img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.2s;
}

.media__img:hover {
    transform: scale(1.05);
}

.media__video {
    width: 200px;
    height: 200px;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.2s;
}

.media__video:hover {
    transform: scale(1.05);
}

.info {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 500px;
}

.fields {
    width: 100%;
}

.field {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #e0e0e0;
}

.text {
    font-size: 16px;
    color: #555;
}

.buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
}

.btn__wrapper {
    display: flex;
    gap: 10px;
}

.btn {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    width: 150px;
}

.btn__decline {
    background-color: #ff4d4d;
    color: #fff;
}

.btn__decline:hover {
    background-color: #ff1a1a;
}

.btn__accept {
    background-color: #4caf50;
    color: #fff;
}

.btn__accept:hover {
    background-color: #45a049;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
}

.modalClose {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    color: #fff;
}

.modalContent {
    max-width: 90%;
    max-height: 90%;
    border-radius: 8px;
}