.container {
    background-color: #232E3C;
    margin: 20px;
    margin-left: 290px;
    padding: 20px;
    height: calc(100dvh - 40px);
    border-radius: 20px;

    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(11.4px);
    -webkit-backdrop-filter: blur(11.4px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.chips {
    height: calc(100dvh - 130px);
    overflow: auto;
}

.chips::-webkit-scrollbar {
    width: 10px;
}

.chips::-webkit-scrollbar-track {
    background: #8b8b8b;
    border-radius: 20px;
}

.chips::-webkit-scrollbar-thumb {
    background-color: #333;
    border-radius: 20px;
    border: 3px solid #8b8b8b;
}

.list__wrapper {
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 15px;
}

.list__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100dvh - 130px);
}

.list__item__title {
    font-size: 16px;
}

.title {
    text-align: center;
    margin-bottom: 20px;
    font-weight: 600;
    color: #333;
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100dvh - 130px);
}

.list__item__info__wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    border-left: 1px solid;
    padding-left: 10px;
}

.list__item__info {
    display: flex;
    flex-direction: column;
}

.list__item__sub__info {
    display: flex;
    justify-content: space-between;
    gap: 5px;
}

.list__item__info p {
    font-size: 17px;
}

.list__item__icon {
    padding-right: 10px;
}

.list__item__info {
    display: flex;
}

.list__item {
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid #333;
}

.list__item__info__referral {
    background: #9deeb9;
}

.list__item__info__delete {
    background: #ff8c8c;
}

.no__data {
    font-size: 16px;
    height: 57px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid;
    background: #fff;
}

.back {
    position: fixed;
    bottom: 0;
    height: 50px;
    width: calc(100% - 40px);
    border: none;
    border-radius: 10px;
    background-color: #307bf6;
    color: #fff;
    font-weight: 600;
    font-size: 13px;
    margin-bottom: 20px;
}

@media (max-width: 300px) {
    .list__item__sub__info {
        margin-top: 5px;
        flex-direction: column;
        gap: 0;
    }

}