.container {
    background-color: #232E3C;
    margin: 20px;
    margin-left: 290px;
    padding: 20px;
    height: calc(100dvh - 40px);
    border-radius: 20px;

    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(11.4px);
    -webkit-backdrop-filter: blur(11.4px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.info__wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 5px;
}

.password__input {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid;
    outline: none;
}

.user__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.title {
    text-align: center;
    font-weight: 500;
    color: #333;
}

.admin__list {
    overflow: auto;
    height: 200px;
    padding-right: 15px;
    padding-left: 15px;
}

.admins {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 10px;
    list-style: none;
}

.admin__list::-webkit-scrollbar {
    width: 10px;
}

.admin__list::-webkit-scrollbar-track {
    background: #8b8b8b;
    border-radius: 20px;
}

.admin__list::-webkit-scrollbar-thumb {
    background-color: #333;
    border-radius: 20px;
    border: 3px solid #8b8b8b;
}

.list::-webkit-scrollbar {
    width: 10px;
}

.list::-webkit-scrollbar-track {
    background: #8b8b8b;
    border-radius: 20px;
}

.list::-webkit-scrollbar-thumb {
    background-color: #333;
    border-radius: 20px;
    border: 3px solid #8b8b8b;
}

.list {
    padding-right: 10px;
    padding-left: 10px;
    list-style: none;
    height: calc(100dvh - 435px);
    overflow: auto;
}

.list__item__info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
    align-items: start;
    background-color: #fff;
    padding: 15px;
    border-radius: 12px;
    cursor: pointer;
    border: 1px solid #333;
}

.hr {
    margin-bottom: 20px;
    margin-top: 20px;
}

.username {
    border-bottom: 1px solid;
    padding-bottom: 5px;
    height: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.input {
    outline: none;
    padding: 10px;
    border-radius: 12px;
    width: 250px;
    border: none;
    border: 1px solid #333;
}

.no__data {
    font-size: 16px;
    height: 57px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background: #fff;
    border: 1px solid;
}

.delete,
.add {
    border: none;
    color: white;
    padding: 7px 14px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    border-radius: 8px;
    cursor: pointer;
    background: #333;
    width: 100%;
    height: 40px;
}