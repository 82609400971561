.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin: 20px;
    margin-left: 290px;

    height: calc(100dvh - 40px);
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(11.4px);
    -webkit-backdrop-filter: blur(11.4px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.title {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
}

.formContainer {
    display: flex;
    justify-content: center;
    gap: 20px;
    width: 100%;
    flex: 1;
}

.form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 350px;
}

.field {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.field label {
    margin-bottom: 5px;
}

.field input,
.field select {
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.button {
    padding: 10px;
    font-size: 16px;
    background: #333;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.button:hover {
    background-color: #0056b3;
}

.listingsContainer {
    width: 100%;
    max-width: 350px;
    overflow-y: auto;
    height: calc(100dvh - 130px);
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 15px;
}

.listingsContainer::-webkit-scrollbar {
    width: 10px;
}

.listingsContainer::-webkit-scrollbar-track {
    background: #8b8b8b;
    border-radius: 20px;
}

.listingsContainer::-webkit-scrollbar-thumb {
    background-color: #333;
    border-radius: 20px;
    border: 3px solid #8b8b8b;
}

.listings {
    display: flex;
    flex-direction: column;
    gap: 10px;
    list-style: none;
    padding: 0;
}

.listing {
    border-bottom: 1px solid #ccc;
}

.listing h3 {
    margin: 0 0 5px 0;
}

.listing {
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding-bottom: 10px;
}

.buttonDelete {
    padding: 5px;
    font-size: 14px;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.buttonDelete:hover {
    background-color: #c82333;
}