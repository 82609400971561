.section {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100dvh;
    background-color: rgba(0, 0, 0, 0.5);
}

.container {
    width: 500px;
    height: 500px;
    background-color: #fff;
    border-radius: 20px;
    padding: 20px;
}

.title {
    text-align: center;
    margin-bottom: 20px;
    font-weight: 500;
    color: #111;
    padding-top: 20px;
}

.list {
    list-style: none;
}

.list__item__info {
    background-color: #fff;
    padding: 15px;
    border-radius: 12px;
    cursor: pointer;
}

.field {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 20px;
}

.input {
    padding: 10px;
    outline: none;
}

.buttons {
    display: flex;
    gap: 10px;
}

.button {
    width: 100%;
    padding: 10px;
    cursor: pointer;
}