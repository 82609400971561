.container {
    position: relative;
    background-color: #232E3C;
    margin: 20px;
    margin-left: 290px;
    padding: 20px;
    height: calc(100dvh - 40px);
    border-radius: 20px;

    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(11.4px);
    -webkit-backdrop-filter: blur(11.4px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.title,
.month__title {
    text-align: center;
    margin-bottom: 20px;
    font-weight: 500;
    color: #333;
}

.month__title {
    margin: 0;
}

.history__title {
    text-align: center;
    font-weight: 500;
    color: #333;
    border-bottom: 1px solid #333;
    padding-bottom: 15px;
}

.date__title {
    text-align: center;
    font-weight: 500;
    color: #333;
    margin-bottom: 10px;
}

.list__wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    overflow-x: auto;
}

.history {
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: auto;
    padding-right: 10px;
    padding-left: 10px;
    height: calc(100dvh - 430px);
}

.history::-webkit-scrollbar {
    width: 10px;
}

.history::-webkit-scrollbar-track {
    background: #8b8b8b;
    border-radius: 20px;
}

.history::-webkit-scrollbar-thumb {
    background-color: #333;
    border-radius: 20px;
    border: 3px solid #8b8b8b;
}

.statisticsTable {
    width: 100%;
    border-collapse: collapse;
}

.statisticsTable th,
.statisticsTable td {
    border: 1px solid #ddd;
    text-align: left;
    padding: 8px;
}

.statisticsTable th {
    background-color: #f2f2f2;
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
}