.authSection {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100vh;
    overflow: hidden;
}

.authBackground {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    filter: blur(4px);
    background-size: cover;
    background-position: center;
    z-index: -1;
}

.authContainer {
    position: relative;
    width: 400px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(11.4px);
    -webkit-backdrop-filter: blur(11.4px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    z-index: 1;
    margin: 15px;

    display: flex;
    flex-direction: column;
    gap: 25px;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 40px
}

.formGroup {
    display: flex;
    flex-direction: column;
    gap: 20px
}

.title {
    text-align: center;
    color: #333;
}

.label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
}

.input {
    width: 100%;
    padding: 8px;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid #111;
    box-sizing: border-box;
    outline: none;
}

.button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 8px;
    background: transparent;
    border: 1px solid #111;
    font-size: 16px;
    cursor: pointer;
    color: #333;

    transition: all 0.3s ease;
}

.button:hover {
    background-color: #111;
    color: #fff;
}

.text {
    font-size: 14px;
    text-align: center;
    margin-top: 5px;
    color: #666;
    cursor: pointer;
    text-decoration: underline;
}