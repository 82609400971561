.section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap: 20px;
    background-color: #232E3C;
    margin: 20px;
    margin-left: 290px;
    padding: 20px;
    height: calc(100dvh - 40px);
    border-radius: 20px;

    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(11.4px);
    -webkit-backdrop-filter: blur(11.4px);
    border: 1px solid rgba(86, 78, 78, 0.3);
}

.settings__wrapper {
    display: flex;
    align-items: start;
    flex-direction: column;
    overflow: auto;
    height: calc(100dvh - 130px);
    width: 100%;
    gap: 10px;
}

.login__container {
    width: 400px;
    background-color: #fff;
    padding: 25px;
    border: 1px solid;
    border-radius: 10px;
}

.settings__wrapper::-webkit-scrollbar {
    width: 10px;
}

.settings__wrapper::-webkit-scrollbar-track {
    background: #8b8b8b;
    border-radius: 20px;
}

.settings__wrapper::-webkit-scrollbar-thumb {
    background-color: #333;
    border-radius: 20px;
    border: 3px solid #8b8b8b;
}

.settings {
    display: flex;
    gap: 10px;
    width: 100%;
}

.title {
    text-align: center;
    font-weight: 500;
    color: #333;
}

.back {
    position: absolute;
    top: 20px;
    left: 20px;
    color: #333;
    cursor: pointer;
    width: 35px;
    height: 35px;
    border: 1px solid #333;
    border-radius: 8px;
    padding: 5px;
}

.container {
    display: flex;
    justify-content: center;
    gap: 30px;
    padding: 25px;
    border: 1px solid;
    border-radius: 10px;
    background: #fff;
    width: 100%;
}

.filed__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
}

.field {
    display: flex;
    gap: 10px;
    flex-direction: column;
    flex: 1;
}

.input {
    padding: 8px;
    border: none;
    background: transparent;
    border-bottom: 1px solid;
    font-size: 16px;
    outline: none;
}

.button,
.button__active {
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 100%;
}

.comission__value {
    display: flex;
    gap: 5px;
}

.comission__input,
.login__input {
    border-bottom: 1px solid;
    width: 40px;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid;
    outline: none;
    text-align: center;
}

.login__input {
    width: 160px;
}

.ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 200px;
    overflow: auto;
    overflow-x: hidden;
    padding-right: 5px;
}

.ul::-webkit-scrollbar {
    width: 10px;
}

.ul::-webkit-scrollbar-track {
    background: #8b8b8b;
    border-radius: 20px;
}

.ul::-webkit-scrollbar-thumb {
    background-color: #333;
    border-radius: 20px;
    border: 3px solid #8b8b8b;
}

.li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #232E3C;
    border-radius: 10px;
    padding: 10px;
}

.comission__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 25px;
    background: #fff;
    border-radius: 15px;
    border: 1px solid;
    width: 100%;
    height: 100%;
    justify-content: center;
}

.comission__field {
    display: flex;
    align-items: end;
}

.container__wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    height: 100%;
    width: 100%;
    justify-content: space-between;
}

.button__active {
    background-color: #4aa361;
    color: #fff;
}

.close {
    cursor: pointer;
}

.enter__wrapper {
    display: flex;
    gap: 10px;
    align-items: end;
}

.enter {
    transform: rotate(180deg);
    width: 30px;
    height: 30px;
    border: 1px solid #333;
    padding: 5px;
    cursor: pointer;
    border-radius: 5px;
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100dvh - 130px);
}

@media (max-width: 1290px) {
    .settings {
        flex-wrap: wrap;
        padding-right: 15px;
    }
}