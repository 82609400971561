.container__wrapper {
    display: flex;
    gap: 10px;
    height: 100%;
}

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    width: 100%;
}

.container {
    display: flex;
    padding: 25px;
    background: #fff;
    border-radius: 15px;
    border: 1px solid;
    width: 100%;
    height: 100%;
}

.refferal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.refferal__list {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 25px;
    border: 1px solid;
    border-radius: 15px;
    width: 100%;
    overflow: auto;
    height: 100%;
    justify-content: space-between;
}

.refferal__list::-webkit-scrollbar {
    width: 0;
}

.refferal__list {
    -ms-overflow-style: none;
}

.refferal__list {
    overflow: -moz-scrollbars-none;
}

.no__data {
    text-align: center;
    border: 1px solid;
    padding: 8px;
    border-radius: 5px;
    background: #fff;
}

.title {
    margin-bottom: 10px;
}

.inputs {
    display: flex;
    gap: 10px;
    flex-direction: column;
    /* align-items: center; */
}

.input {
    border-radius: 10px;
    padding: 10px;
    border: 1px solid;
    outline: none;
}

.button {
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 100%;
}

.list {
    flex: 1;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.list__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid;
}

.delete {
    width: 22px;
    height: 22px;
}

.close {
    cursor: pointer;
    width: 22px;
    height: 22px;
}

.icon__wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: #333;
    border-radius: 5px;
}

.arrow {
    width: 25px;
    color: #fff;
    height: 25px;
}