.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin: 20px;
    margin-left: 290px;
    background-color: #f9f9f9;
    min-height: calc(100dvh - 40px);
    position: relative;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(11.4px);
    -webkit-backdrop-filter: blur(11.4px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.back {
    cursor: pointer;
    width: 30px;
    height: 30px;
    position: absolute;
    left: 20px;
    top: 20px;
}

.title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

.info {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.field {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    gap: 5px;
}

.text {
    font-size: 16px;
    color: #666;
}

.text__bold {
    font-weight: bold;
    color: #333;
}

.copy__icon {
    cursor: pointer;
    font-size: 20px;
    color: #666;
}

.copy__icon:hover {
    color: #333;
}

.buttons {
    display: flex;
    justify-content: center;
    width: 100%;
}

.btn__wrapper {
    display: flex;
    gap: 10px;
}

.btn {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 150px;
}

.btn__accept {
    background-color: #28a745;
    color: white;
}

.btn__decline {
    background-color: #dc3545;
    color: white;
}

.btn:hover {
    opacity: 0.9;
}

/* Styles for different types */
.type-base .info {
    border-left: 5px solid #007bff;
}

.type-hide .info {
    border-left: 5px solid #007bff;
}

.type-referral .info {
    border-left: 5px solid #17a2b8;
}

.type-delete .info {
    border-left: 5px solid #dc3545;
}

.type-go_out_referral .info {
    border-left: 5px solid #ffc107;
}